import "../Reports/Reports.css";
import "./InsightsV3.css";
import lightbulb from "../../assets/lightbulb.svg";
import whiteLuxi from "../../assets/white-logo-only-icon.png";
import darkLuxi from "../../assets/small-icon-hires.png";
import { SelectedProjectContext, UserInfoContext } from "../../contexts";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import {
  modifyDropdowns,
  removeDuplicates,
  getUxErrorLabel,
  getUxErrorIcon,
  getLabel,
  deleteInsightsFastDoc,
} from "../../helpers";
import Modal from "react-modal";
import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  Spinner,
  Tooltip,
  OverlayTrigger,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export function InsightsV3(props) {
  const selectedProject = useContext(SelectedProjectContext);
  const userInfo = useContext(UserInfoContext);

  const [isLoading, setIsLoading] = useState(true);
  const [insights, setInsights] = useState([]);
  const [filteredInsights, setFilteredInsights] = useState([]);

  const [selectedInsight, setSelectedInsight] = useState({});
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const location = useLocation();

  const [url, setUrl] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [insightIdToDelete, setInsightIdToDelete] = useState(null);

  const navigate = useNavigate();

  async function deleteInsight(insightId) {
    setInsights(insights.filter((insight) => insight.id !== insightId));
    setFilteredInsights(
      filteredInsights.filter((insight) => insight.id !== insightId),
    );
    setSelectedInsight(filteredInsights[0] ?? {});
    toast.success("Insight deleted!");
    try {
      await deleteInsightsFastDoc(insightId);
      await deleteDoc(doc(db, "insights", insightId));
    } catch (error) {
      toast.fail("Insight deletion failed");
      return;
    }
  }

  async function updatePreviousInsight() {
    if (!selectedInsight?.seen && selectedInsight?.id) {
      const idoc = doc(db, "insights", selectedInsight?.id);
      const docSnapshot = await getDoc(idoc);
      if (docSnapshot.exists()) {
        await updateDoc(idoc, { seen: true });
        setInsights((prevInsights) => {
          return prevInsights.map((item) => {
            if (item.id === selectedInsight.id) {
              return { ...item, seen: true };
            } else {
              return item;
            }
          });
        });
      }
    }
  }
  const actions = [
    {
      icon: <img src={darkLuxi} style={{ width: "17.5px", height: "20px" }} />,
      label: "Ask the AI to fix it",
      description:
        "The AI will propose you a new html/CSS. <br/> You can directly send it to your developpers 🔥",
      button: "Fix it",
      hidden:
        !["1_2_1", "1_3_3", "3_1_1"].includes(selectedInsight?.ruleId) ||
        !selectedInsight?.DOMId,
      onClick: () => {
        navigate("/fixit", {
          state: {
            selectedInsight: selectedInsight,
            currentProject: selectedProject,
          },
        });
      },
    },
    {
      icon: <i className="fa-regular fa-share"></i>,
      label: "Share with your team",
      description: "",
      button: "Share",
      onClick: () => {
        toast.warn("This feature is not available yet.");
      },
    },
    /* INFO: temprorary hide this feature
    {
      icon: <i className="fa-brands fa-jira"></i>,
      label: "Create a Jira ticket",
      description: "",
      button: "Create a ticket",
      onClick: () => {
        toast.warn("This feature is not available yet.");
      },
    }, */
  ];

  // INFO: hide actions only for reader
  if (!["reader"]?.includes(userInfo?.role)) {
    actions.push(
      ...[
        {
          icon: <i className="fa-regular fa-check"></i>,
          label: "Mark as solved",
          description:
            "Soon you mark an insight as solved, you can follow the metric <br />to that insight to see how it has impact your product 📈",
          button:
            selectedInsight?.status == "complete"
              ? "Mark as unsolved"
              : "Mark as solved",
          onClick: () => toggleInsightStatus(selectedInsight),
        },
        {
          icon: <i className="fa-regular fa-trash"></i>,
          label: "Delete or not relevant",
          description: "",
          button: "Delete",
          onClick: () => {
            setInsightIdToDelete(selectedInsight.id);
            setIsDeleteModalOpen(true);
          },
        },
      ],
    );
  }

  const generateUrl = () => {
    if (
      selectedInsight &&
      selectedInsight.pageName &&
      selectedProject &&
      selectedProject.rootUrl
    ) {
      let pageName = selectedInsight.pageName;

      // If pageName starts with www., http://, or https://, return pageName
      if (
        pageName.startsWith("www.") ||
        pageName.startsWith("http://") ||
        pageName.startsWith("https://")
      ) {
        return pageName;
      }

      let rootUrl = selectedProject.rootUrl.replace(/\/$/, "");
      const path = pageName ? "/" + pageName.replace(/^\//, "") : "";

      if (!rootUrl.startsWith("http://") && !rootUrl.startsWith("https://")) {
        rootUrl = "http://" + rootUrl;
      }

      return rootUrl + path;
    }
    return "#";
  };
  useEffect(() => {
    if (selectedInsight.screenshotUrl) {
      setInsightImgLoading(true);
      const img = new Image();
      img.onload = () => setInsightImgLoading(false);
      img.onerror = () => setInsightImgLoading(false);
      img.src = selectedInsight.screenshotUrl;
    } else {
      setInsightImgLoading(false);
    }
  }, [selectedInsight.screenshotUrl]);

  function strippedInsights(insights) {
    // Remove fields not shown in the table ex. filter values like report and page
    return insights.map((insight) => {
      const { id, pageName, reportId, date, ...result } = insight;
      for (const key in result) {
        if (result[key] === null || result[key] === undefined) {
          result[key] = "N/A";
        }
      }

      return {
        ...result,
        id: reportId,
        isInsight: true,
      };
    });
  }

  const populateInsightsAsync = async (pid) => {
    await populateInsights(pid);
    setIsLoading(false);
  };

  const toggleInsightStatus = async (insight) => {
    const newStatus =
      insight.status === "complete" ? "in-progress" : "complete";
    const insightRef = doc(db, "insights", insight.id);

    // Update Firestore document
    await updateDoc(insightRef, {
      status: newStatus,
      completionDate: new Date(),
    });

    // Update the insights state
    setInsights((prevInsights) =>
      prevInsights.map((item) =>
        item.id === insight.id ? { ...item, status: newStatus } : item,
      ),
    );

    setFilteredInsights((prevInsights) =>
      prevInsights.map((item) =>
        item.id === insight.id ? { ...item, status: newStatus } : item,
      ),
    );

    // Update the selectedInsight state
    setSelectedInsight((prevState) => ({ ...prevState, status: newStatus }));

    // Show toast notification
    toast.success(`Insight marked as ${newStatus}!`);
  };

  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(location.search),
  );

  const [filterByMetric, setFilterByMetric] = useState(
    location.state?.filterByMetric ||
      searchParams.get("errorType") === "metric" ||
      false,
  );

  const defaultFilter = { value: "All", label: "All" };

  const [isPageMenuOpen, setIsPageMenuOpen] = useState(false);
  const [isEaseToFixMenuOpen, setIsEaseToFixMenuOpen] = useState(false);
  const [isSeverityMenuOpen, setIsSeverityMenuOpen] = useState(false);
  const [isFixStatusMenuOpen, setIsFixStatusMenuOpen] = useState(false);
  const [isSeenMenuOpen, setIsSeenMenuOpen] = useState(false);
  const [isSolvedMenuOpen, setIsSolvedMenuOpen] = useState(false);

  const [selectedPage, setSelectedPage] = useState(defaultFilter);
  const [selectedReport, setSelectedReport] = useState(defaultFilter);
  const [selectedEaseToFix, setSelectedEaseToFix] = useState(defaultFilter);
  const [selectedSeverity, setSelectedSeverity] = useState(defaultFilter);
  const [selectedFixStatus, setSelectedFixStatus] = useState(defaultFilter);
  const [selectedSeen, setSelectedSeen] = useState(defaultFilter);
  const [selectedSolved, setSelectedSolved] = useState(defaultFilter);

  const [selectedType, setSelectedType] = useState(
    filterByMetric
      ? { value: "metric", label: "Performance Metric" }
      : defaultFilter,
  );
  const [isTypeMenuOpen, setIsTypeMenuOpen] = useState(false);

  const [insightImgLoading, setInsightImgLoading] = useState(false);
  // Tabs
  const [tabKey, setTabKey] = useState("insight");

  const [id, setId] = useState(searchParams.get("id"));
  const [pid, setPid] = useState(searchParams.get("pid"));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSearchParams(params);
    setId(params.get("id"));
    setPid(params.get("pid"));
  }, [location]);

  useEffect(() => {
    if (pid && props.projects) {
      const project = props.projects.find((project) => project.value === pid);
      props.setSelectedProject(project ?? props.projects[0] ?? {});
      if (!project) {
        toast.warn("The requested project was not found");
      }
    }
  }, [props.projects, pid]);

  useEffect(() => {
    setIsLoading(true);
    populateInsightsAsync(pid);
  }, [selectedProject, id]);

  const filterNames = [
    "Page",
    "Observation Types",
    "New / Already seen",
    "Solved / Not solved",
    "Ease to Fix",
    "Severity",
  ];

  const onSelect = (option, menuType) => {
    switch (menuType) {
      case "Page":
        setSelectedPage(option);
        modifyDropdowns(0, "value", option, setDropdowns);
        break;
      case "Observation Types":
        setSelectedType(option);
        modifyDropdowns(1, "value", option, setDropdowns);
        break;
      case "New / Already seen":
        setSelectedSeen(option);
        modifyDropdowns(2, "value", option, setDropdowns);
        break;
      case "Solved / Not solved":
        setSelectedSolved(option);
        modifyDropdowns(3, "value", option, setDropdowns);
        break;
      case "Ease to Fix":
        setSelectedEaseToFix(option);
        modifyDropdowns(4, "value", option, setDropdowns);
        break;
      case "Severity":
        setSelectedSeverity(option);
        modifyDropdowns(5, "value", option, setDropdowns);
        break;
      default:
        break;
    }
  };

  const onToggleMenu = (state, menuType) => {
    const index = filterNames.indexOf(menuType);
    if (index !== -1) {
      modifyDropdowns(index, "isMenuOpen", state, setDropdowns);
    }
  };

  const filterInsights = (i) => {
    // Filter the table on change of filter selection
    const filteredInsights = i.filter((r) => {
      const pageFilter =
        selectedPage.value === "All" || r.pageName === selectedPage.value;
      const easeFilter =
        selectedEaseToFix.value === "All" ||
        (selectedEaseToFix.value == null && r.fixEase == null) ||
        r.fixEase === selectedEaseToFix.value;
      const seenFilter =
        selectedSeen.value === "All" ||
        (selectedSeen.value === "New" && !r?.seen) ||
        (selectedSeen.value === "Already seen" && r.seen);
      const solvedFilter =
        selectedSolved.value === "All" ||
        (selectedSolved.value === "in-progress" && !r.status) ||
        r.status === selectedSolved.value;
      const severityFilter =
        selectedSeverity.value === "All" ||
        (selectedSeverity.value == null && r.severity == null) ||
        r.severity === selectedSeverity.value;
      const statusFilter =
        selectedFixStatus.value === "All" ||
        r.fixStatus === selectedFixStatus.value;
      const typeFilter =
        selectedType.value === "All" ||
        r.uxErrorType.some((type) => type.value === selectedType.value);

      return (
        pageFilter &&
        easeFilter &&
        seenFilter &&
        solvedFilter &&
        severityFilter &&
        statusFilter &&
        typeFilter
      );
    });
    return filteredInsights;
  };

  useEffect(() => {
    const filteredInsights = filterInsights(insights);
    updatePreviousInsight();
    setFilteredInsights(filteredInsights);
    setSelectedInsight(filteredInsights[0] ?? {});
  }, [
    selectedPage.value,
    selectedType.value,
    selectedSeen.value,
    selectedSolved.value,
    selectedEaseToFix.value,
    selectedSeverity.value,
  ]);

  const [dropdowns, setDropdowns] = useState([
    {
      name: "Page",
      options: [defaultFilter],
      value: selectedPage,
      isMenuOpen: isPageMenuOpen,
      menuOpenHandler: () => setIsPageMenuOpen(true),
      menuCloseHandler: () => setIsPageMenuOpen(false),
    },
    {
      name: "Observation Types",
      options: [defaultFilter],
      value: selectedType,
      isMenuOpen: isTypeMenuOpen,
      menuOpenHandler: () => setIsTypeMenuOpen(true),
      menuCloseHandler: () => setIsTypeMenuOpen(false),
    },
    {
      name: "New / Already seen",
      options: [
        { value: "All", label: "All" },
        { value: "New", label: "New" },
        { value: "Already seen", label: "Already seen" },
      ],
      value: selectedSeen,
      isMenuOpen: isSeenMenuOpen,
      menuOpenHandler: () => setIsSeenMenuOpen(true),
      menuCloseHandler: () => setIsSeenMenuOpen(false),
    },
    {
      name: "Solved / Not solved",
      options: [
        { value: "All", label: "All" },
        { value: "complete", label: "Solved" },
        { value: "in-progress", label: "Not Solved" },
      ],
      value: selectedSolved,
      isMenuOpen: isSolvedMenuOpen,
      menuOpenHandler: () => setIsSolvedMenuOpen(true),
      menuCloseHandler: () => setIsSolvedMenuOpen(false),
    },
    {
      name: "Ease to Fix",
      options: [defaultFilter],
      value: selectedEaseToFix,
      isMenuOpen: isEaseToFixMenuOpen,
      menuOpenHandler: () => setIsEaseToFixMenuOpen(true),
      menuCloseHandler: () => setIsEaseToFixMenuOpen(false),
    },
    {
      name: "Severity",
      options: [defaultFilter],
      value: selectedSeverity,
      isMenuOpen: isSeverityMenuOpen,
      menuOpenHandler: () => setIsSeverityMenuOpen(true),
      menuCloseHandler: () => setIsSeverityMenuOpen(false),
    },
  ]);

  const populateInsights = async (pid) => {
    if (!selectedProject?.value && !pid) return;
    const projectId = pid || selectedProject.value;

    const querySnapshot = await getDocs(
      query(collection(db, "insights"), where("projectId", "==", projectId)),
    );

    const insightsArrays = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    try {
      let insightsListTemp = insightsArrays;
      // Order by num, which will similar to creation date
      insightsListTemp.sort((a, b) => a.num - b.num);

      const pageList = [];
      const easeToFixList = [];
      const severityList = [];
      const fixStatusList = [];
      const typeList = [];

      // Populate insight details and dropdowns that require all data

      insightsListTemp.forEach((item, index) => {
        item.fixStatus =
          item.status === "in-progress" ? "In progress" : "No action taken";
        const insightNumber = `Insight #${item.num}`;
        item.number = insightNumber;

        //Populate dropdown options
        pageList.push({
          value: item.pageName,
          label: item.pageName,
        });
        easeToFixList.push({
          value: item.fixEase,
          label: item.fixEase || "N/A",
        });
        severityList.push({
          value: item.severity,
          label: item.severity || "N/A",
        });
        item.uxErrorType?.forEach((type) => {
          typeList.push({
            value: type.value,
            label: getUxErrorLabel(type.value),
          });
        });
        fixStatusList.push({
          value:
            item.status === "in-progress" ? "In progress" : "No action taken",
          label:
            item.status === "in-progress" ? "In progress" : "No action taken",
        });
      });

      setDropdowns((prevDropdowns) => [
        {
          ...prevDropdowns[0],
          options: [defaultFilter, ...removeDuplicates(pageList)],
        },
        {
          ...prevDropdowns[1],
          options: [defaultFilter, ...removeDuplicates(typeList)],
        },
        {
          ...prevDropdowns[2],
        },
        {
          ...prevDropdowns[3],
        },
        {
          ...prevDropdowns[4],
          options: [defaultFilter, ...removeDuplicates(easeToFixList)],
        },
        {
          ...prevDropdowns[5],
          options: [defaultFilter, ...removeDuplicates(severityList)],
        },
      ]);

      setInsights(insightsListTemp);
      if (filterByMetric) {
        insightsListTemp = filterInsights(insightsListTemp);
      }
      setFilteredInsights(insightsListTemp);

      if (id && insightsListTemp) {
        const selectedInsight = insightsListTemp.find(
          (insight) => insight.id === id,
        );
        const insightToSelect = selectedInsight ?? insightsListTemp[0];
        if (!selectedInsight) {
          toast.warn("The requested insight was not found.");
        }
        setSelectedInsight(insightToSelect);
      } else if (location?.state?.selectedInsightAdmin) {
        const selectedInsight = insightsListTemp.find(
          (insight) => insight.id === location?.state?.selectedInsightAdmin,
        );
        setSelectedInsight(selectedInsight ?? {});
      } else {
        setSelectedInsight(insightsListTemp[0] ?? {});
      }
    } catch (error) {}
  };

  const insightRuleCard = useMemo(
    () =>
      selectedInsight?.uxRule && (
        <div className="insight-rule-card">
          <div className="d-flex align-items-center">
            <img
              src={lightbulb}
              alt="lightbulb"
              style={{ marginRight: "8px" }}
            />
            <div className="fs-12 fw-900 lh-245">TO NOT DIE STUPID</div>
          </div>
          <div
            className="fs-16 fw-400 lh-2695"
            style={{
              marginTop: "10px",
              width: "620px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
            }}
          >
            {selectedInsight.uxRule}
          </div>
        </div>
      ),
    [selectedInsight.uxRule],
  );

  const goToPage = useMemo(
    () => (
      <div className="goToPage-wrapper">
        <div className="fs-16 fc-blue lh-25 w-100 d-flex align-items-center justify-content-start">
          <a
            href={generateUrl()}
            target="_blank"
            rel="noreferrer"
            className="mw-700 wrapword"
          >
            {/* <i
              className="fa-regular fa-arrow-up-right-from-square ms-1"
              style={{ marginRight: "10px" }}
            ></i> */}
            {generateUrl()}
          </a>
        </div>
      </div>
    ),
    [selectedInsight?.id, generateUrl],
  );

  const screenshotSection = useMemo(
    () => (
      <div className="insight-screenshot-section">
        <div>
          {insightImgLoading && (
            <Spinner style={{ width: "50px", height: "50px" }} />
          )}

          {selectedInsight?.screenshotUrl ? (
            <img
              key={selectedInsight?.id}
              src={selectedInsight?.screenshotUrl}
              style={{
                maxWidth: "700px",
                border: "4.9px solid #000000",
                borderRadius: "9.8px",
                display: insightImgLoading ? "none" : "block",
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    ),
    [selectedInsight?.id, insightImgLoading, selectedInsight?.screenshotUrl],
  );

  const tabActions = useMemo(() => {
    return (
      <div className="fc-blue actions-list" style={{ width: "700px" }}>
        {actions?.map(
          (action, i) =>
            !action.hidden && (
              <div
                key={`action-${i}-${selectedInsight?.id}`}
                className={`action-item ${
                  !!action?.description ? "has-desc" : ""
                }`}
              >
                <span className="context">
                  <span className="top">
                    {action?.icon && (
                      <span className="icon">{action.icon}</span>
                    )}
                    {action?.label && (
                      <span className="label">{action.label}</span>
                    )}
                  </span>
                  {!!action?.description && (
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: action?.description }}
                    />
                  )}
                </span>
                {!!action?.button && (
                  <Button variant="outline-primary" onClick={action?.onClick}>
                    {action?.button}
                  </Button>
                )}
              </div>
            ),
        )}
      </div>
    );
  }, [actions, selectedInsight?.id, selectedInsight?.status]);

  const tabInsight = useMemo(() => {
    return (
      <>
        <div
          className="fw-700 fs-24 lh-324 fc-black"
          style={{ marginTop: "30px" }}
        >
          #{selectedInsight.num} {selectedInsight.title}
          {goToPage}
        </div>
        <div
          className="fw-400 fs-18 lh-25 fc-black wrap"
          style={{
            width: "650px",
            marginTop: "24px",
            marginBottom: "25px",
            whiteSpace: "pre-wrap",
          }}
        >
          {processDescriptionGeneral(selectedInsight)}

          {processDescriptionWhole(selectedInsight)?.split("-!-")?.length >
            1 && (
            <div style={{ marginTop: "1em" }}>
              {processDescriptionWhole(selectedInsight)
                .split("-!-")
                .slice(1)
                .map((point, index) => {
                  // Only render point if it has text
                  if (!point.trim()) {
                    return null;
                  }

                  return (
                    <div
                      key={index}
                      style={{
                        marginLeft: "2em",
                        marginTop: "0.5em",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: "-1em",
                        }}
                      >
                        •
                      </span>
                      {point}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        {(selectedInsight?.fixEase ||
          selectedInsight?.severity ||
          selectedInsight.uxErrorType?.length > 0) && (
          <div className="d-flex" style={{ marginBottom: "50px" }}>
            {selectedInsight?.uxErrorType &&
              selectedInsight?.uxErrorType?.length > 0 && (
                <div
                  style={{
                    borderRight:
                      selectedInsight?.severity || selectedInsight?.fixEase
                        ? "1px solid #D7D7D7"
                        : "none",
                    marginRight: "32px",
                    paddingRight: "32px",
                  }}
                >
                  <div className="fs-14 fw-900 fc-grey lh-245">
                    OBSERVATION TYPES
                  </div>
                  <div
                    className="d-flex"
                    style={{
                      marginTop: "11px",
                      minWidth: "146px",
                    }}
                  >
                    {selectedInsight?.uxErrorType?.map((err, index) => (
                      <div
                        className="mr-4 fs-20 border-right-last-child"
                        style={{ marginRight: "32px" }}
                        key={index}
                      >
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              {" "}
                              {getUxErrorLabel(err.value)}
                            </Tooltip>
                          }
                        >
                          <div
                            className="vhc fvc"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getUxErrorIcon(err.value)}
                          </div>
                        </OverlayTrigger>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            {selectedInsight?.severity && (
              <div
                style={{
                  marginRight: "20px",
                }}
              >
                <div className="fs-14 fw-900 fc-grey lh-245">SEVERITY</div>
                <div
                  className="fs-16 fw-500 fc-grey lh-2695"
                  style={{ marginTop: "11px" }}
                >
                  {getLabel(selectedInsight.severity)}
                </div>
              </div>
            )}
            {selectedInsight?.fixEase && (
              <div style={{ marginRight: "20px" }}>
                <div className="fs-14 fw-900 fc-grey lh-245">EASE TO FIX</div>
                <div
                  className="fs-16 fw-500 fc-grey lh-2695"
                  style={{ marginTop: "11px" }}
                >
                  {getLabel(selectedInsight.fixEase)}
                </div>
              </div>
            )}
          </div>
        )}

        {screenshotSection}
        {selectedInsight?.element?.length > 0 && (
          <div className="insight-rule-card" style={{ paddingTop: "0px" }}>
            <div
              className="d-flex align-items-center"
              onClick={() =>
                setShowErrorDetails(
                  (prevShowErrorDetails) => !prevShowErrorDetails,
                )
              }
              style={{
                paddingTop: "24px",
                paddingBottom: "24px",
                marginBottom: "-24px",
              }}
            >
              <i className="fa-regular fa-triangle-exclamation fs-20"></i>
              <div
                className="fs-12 fw-900 lh-245"
                style={{ marginLeft: "10px" }}
              >
                DETAILS
              </div>

              <div
                style={{ flexGrow: 1 }}
                className="d-flex align-items-center justify-content-end"
              >
                {showErrorDetails ? (
                  <FiChevronUp alt="chevron up" color="#707070" size="24px" />
                ) : (
                  <FiChevronDown
                    alt="chevron down"
                    color="#707070"
                    size="24px"
                  />
                )}
              </div>
            </div>
            <div
              className="fs-16 fw-400 lh-2695"
              style={{
                marginTop: "10px",
                width: "620px",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                display: showErrorDetails ? "block" : "none",
              }}
            >
              <div
                className="fw-500 fs-16 lh-20 fc-grey"
                style={{
                  marginTop: "10px",
                  marginBottom: "25px",
                  maxWidth: "550px",
                }}
              >
                Copy the code ({" "}
                <i className="fa-regular fa-copy fs-20 lh--3"></i> ) directly
                and send it to the developers.
              </div>
              {selectedInsight?.element.map((element, index) => (
                <div key={index} className="error-element">
                  <i
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(element);
                        toast.success("Copied to clipboard!");
                      } catch (err) {
                        toast.error("Failed to copy text: " + err);
                      }
                    }}
                    className="fa-regular fa-copy fs-20 lh--3 fc-white"
                    style={{ float: "right" }}
                  ></i>
                  <div style={{ width: "420px" }} className="fc-white">
                    {element
                      ? element
                          .replace(/</g, "&lt;")
                          .replace(/>/g, "&gt;")
                          .split(/(&lt;[\s\S]*?&gt;)/)
                          .map((part, index) =>
                            /&lt;[\s\S]*?&gt;/.test(part) ? (
                              <span key={index} className="fc-green2">
                                {part
                                  .replace(/&lt;/g, "<")
                                  .replace(/&gt;/g, ">")}
                              </span>
                            ) : (
                              <span key={index}>{part}</span>
                            ),
                          )
                      : "This insight has no associated element"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {selectedInsight.recommendation &&
          selectedInsight.recommendation.length > 0 && (
            <div
              className="insight-rec-card"
              style={{
                marginTop: "37px",
                marginBottom: "16px",
              }}
            >
              <div className="d-flex align-items-center">
                <img
                  src={lightbulb}
                  alt="lightbulb"
                  style={{ marginRight: "8px" }}
                />
                <div className="fs-12 fw-900 lh-245">RECOMMENDATIONS</div>
              </div>
              <div
                className="fs-16 fw-400 lh-2695 wrap"
                style={{
                  marginTop: "10px",
                  maxWidth: "620px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {processRecommendationGeneral(selectedInsight)}

                {processRecommendationWhole(selectedProject)?.split("-!-")
                  ?.length > 1 && (
                  <div style={{ marginTop: "1em" }}>
                    {processRecommendationWhole(selectedProject)
                      ?.split("-!-")
                      ?.slice(1)
                      .map((point, index) => {
                        // Only render point if it has text
                        if (!point.trim()) {
                          return null;
                        }

                        return (
                          <div
                            key={index}
                            style={{
                              marginLeft: "2em",
                              marginTop: "0.5em",
                              position: "relative",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                left: "-1em",
                              }}
                            >
                              •
                            </span>
                            {point}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              {selectedInsight?.status &&
                selectedInsight?.status === "complete" && (
                  <div className="d-flex" style={{ marginTop: "17px" }}>
                    <div className="fs-16 fw-900 lh-245">
                      This insight has been completed.
                    </div>
                  </div>
                )}
            </div>
          )}
        {insightRuleCard}
      </>
    );
  }, [selectedInsight, showErrorDetails]);

  function processDescriptionGeneral(selectedInsight) {
    if (!selectedInsight || !selectedInsight.description) {
      return "";
    }

    let description = Array.isArray(selectedInsight.description)
      ? selectedInsight.description?.join("\n")
      : selectedInsight.description;

    if (selectedInsight?.title?.startsWith("[WCAG]")) {
      description = description.split("-!-")[0];
    }

    return description;
  }

  function processDescriptionWhole(selectedInsight) {
    if (!selectedInsight || !selectedInsight.description) {
      return "";
    }

    let description = Array.isArray(selectedInsight.description)
      ? selectedInsight.description.join("\n")
      : selectedInsight.description;

    return description;
  }

  function processRecommendationGeneral(selectedInsight) {
    if (!selectedInsight || !selectedInsight.recommendation) {
      return "";
    }

    let recommendation = Array.isArray(selectedInsight.recommendation)
      ? selectedInsight.recommendation.join("\n")
      : selectedInsight.recommendation;

    if (selectedInsight.title.startsWith("[WCAG]")) {
      recommendation = recommendation.split("-!-")[0];
    }

    return recommendation;
  }
  function processRecommendationWhole(selectedInsight) {
    if (!selectedInsight || !selectedInsight.recommendation) {
      return "";
    }

    let recommendation = Array.isArray(selectedInsight.recommendation)
      ? selectedInsight.recommendation.join("\n")
      : selectedInsight.recommendation;

    return recommendation;
  }

  const BottomTabs = useMemo(() => {
    return (
      <Tabs
        id="bottom-insight-tabs"
        activeKey={tabKey}
        onSelect={(k) => setTabKey(k)}
        style={{ marginTop: "-10px", width: "700px" }}
      >
        <Tab eventKey="insight" title="Insights">
          {tabInsight}
        </Tab>
        <Tab eventKey="actions" title="Actions">
          {tabActions}
        </Tab>
        <Tab eventKey="status" title="Status">
          <h2 style={{ marginTop: "32px" }}>Work in progress!</h2>
        </Tab>
      </Tabs>
    );
  }, [tabKey, selectedInsight?.id, selectedInsight?.status, showErrorDetails]);

  return (
    <div>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Insight Delete Modal"
        className="insight-delete-modal modal-shape d-flex flex-column align-items-center justify-content-around"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgba(7, 21, 43, 0.7)",
            zIndex: 10,
          },
        }}
      >
        <h4 className="text-center">
          Are you sure you want to delete this insight?
        </h4>
        <div className="d-flex justify-content-center insight-delete-button-height">
          <Button
            type="button"
            className="btn-primary me-3"
            onClick={() => {
              deleteInsight(insightIdToDelete);
              setIsDeleteModalOpen(false);
              setTabKey("insight");
            }}
          >
            Delete
          </Button>
          <Button
            type="button"
            className="btn-secondary"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <div>
        {isLoading ? (
          <div className="spinner-parent">
            <Spinner style={{ width: "50px", height: "50px" }} />
          </div>
        ) : insights.length === 0 ? (
          <h5 style={{ marginTop: "50px" }}>
            No insights for this project to show yet. You'll find your insights
            here when they are ready!
          </h5>
        ) : (
          <div>
            <div
              style={{ width: "100px", height: "30px", marginTop: "40px" }}
              className="fs-24 fw-700 lh-243 "
            >
              Insights
            </div>
            <div
              style={{
                marginTop: "32.16px",
              }}
            >
              <p
                className="fs-18 fw-500 fc-black lh-243"
                style={{ marginBottom: "10.37px" }}
              >
                We identified{" "}
                <span className="fw-700">
                  {insights?.length ?? 0} areas for improvement
                </span>
              </p>
              <div className="fs-16 fw-500 lh-20">
                <p className="m-0">We know. It is impressive.</p>
                <p>We suggest you start with the key severity points.</p>
              </div>
            </div>
            <div className="filter-section">
              <div className="filter-section-title">Filters</div>
              <div className="d-flex">
                {dropdowns.map((dropdown, i) => (
                  <div key={i} className="dropdown-filter-width-160">
                    <CustomDropdown
                      options={dropdown.options}
                      onChange={(option) => onSelect(option, dropdown.name)}
                      placeholder={dropdown.name}
                      value={dropdown.value}
                      unstyled
                      setMenuOpenState={(res) =>
                        onToggleMenu(res, dropdown.name)
                      }
                      menuOpenState={dropdown.isMenuOpen}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div
              id="back-to-top-anchor"
              style={{ marginTop: "-49.43px", paddingBottom: "49.43px" }}
            ></div>
            <div>
              {filteredInsights.length === 0 ? (
                <h5>No insights found using the selected filters.</h5>
              ) : (
                <div className="d-flex">
                  <div
                    style={{
                      maxHeight: "1000px",
                      minWidth: "435px",
                      overflow: "auto",
                    }}
                  >
                    {filteredInsights.map((insight, index) => {
                      return (
                        <button
                          className={
                            selectedInsight.id == insight.id
                              ? "insight-list-item-active"
                              : "insight-list-item"
                          }
                          key={index}
                          style={{
                            display: "block",
                            width: "100%",
                            cursor: "pointer",
                            textAlign: "left",
                            transition: "none",
                            padding:
                              selectedInsight.id == insight.id
                                ? "20px 55px 20px 37.5px"
                                : "20px 55px 20px 55px",
                          }}
                          onClick={() => {
                            updatePreviousInsight();
                            setSelectedInsight(insight);
                            document
                              .getElementById("back-to-top-anchor")
                              .scrollIntoView({ behavior: "smooth" });
                          }}
                        >
                          <div className="fs-16" style={{ display: "flex" }}>
                            {selectedInsight.id == insight.id && (
                              <img
                                src={whiteLuxi}
                                alt="white luxi"
                                style={{
                                  position: "relative",
                                  width: "17.5px",
                                  height: "20px",
                                  transform: "translate(-15px, 0px)",
                                }}
                              ></img>
                            )}
                            <div>
                              <div className="fw-700">
                                Insight #{insight.num}
                              </div>
                              <div
                                className={
                                  selectedInsight.id == insight.id
                                    ? "fw-700"
                                    : "fw-500"
                                }
                              >
                                {insight.title}
                              </div>
                            </div>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                  {selectedInsight && selectedInsight?.id && (
                    <div className="insight-info">{BottomTabs}</div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
