import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import "./SignIn.css";
import CustomInput from "../CustomInput/CustomInput";
import googleLoginIcon from "../../assets/google-login.png";
import arrowRight from "../../assets/arrow-right.svg";
import logo from "../../assets/white-logo-only-icon.png";
import React, { useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import "bootstrap/dist/css/bootstrap.min.css";
import fadedLogo from "../../assets/faded-logo.png";
import check from "../../assets/check.png";
import PasswordStrengthBar from "react-password-strength-bar";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import {
  createChatRoom,
  dateReactToFirestore,
  getInvitedWorkspace,
  newUserNotification,
  userExists,
} from "../../helpers";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Twemoji from "react-twemoji";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function SignInContent() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [badCredentials, setBadCredentials] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [stackVertical, setStackVertical] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [checkedAgreeToTerms, setCheckedAgreeToTerms] = useState(false);

  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryCitiesMap, setCountryCitiesMap] = useState({});

  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);

  const [validEmail, setValidEmail] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [isSSO, setIsSSO] = useState(false);

  const [pageMode, setPageMode] = useState("login");

  const pid = useParams();
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [verifying, setVerifying] = useState(false);

  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const inviteEmail = urlParams.get("email");

  const [pass1cap, setPass1cap] = useState(false);
  const [pass1num, setPass1num] = useState(false);
  const [pass8char, setPass8char] = useState(false);
  const [pass1spec, setPass1spec] = useState(false);

  function countryCodeToFlag(countryCode) {
    return (
      countryCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        ) + "  "
    );
  }

  const getCountriesAndCities = async () => {
    const country_info = await fetch(
      "https://countriesnow.space/api/v0.1/countries",
    );

    const country_data = await country_info.json();

    setCountryOptions(
      country_data.data.map((country) => {
        return {
          label: (
            <Twemoji options={{ className: "twemoji" }}>
              {countryCodeToFlag(country.iso2) + country.country}
            </Twemoji>
          ),
          countryCode: country.iso2,
          value: country.country,
        };
      }),
    );

    const countryCitiesMapTemp = {};
    for (const { country, cities } of country_data.data) {
      countryCitiesMapTemp[country] = cities.map((city) => {
        return { label: city, value: city };
      });
    }

    setCountryCitiesMap(countryCitiesMapTemp);
  };

  useEffect(() => {
    setCityOptions(countryCitiesMap[country?.value]);
  }, [country]);

  useEffect(() => {
    getCountriesAndCities();
    if (pid["pid"]) {
      setPageMode(auth.currentUser ? "login" : "invite");
      if (auth.currentUser) {
        auth.signOut();
      }
      setEmail(inviteEmail);
      setLoading(true);
    }
  }, []);

  const onSelect = (option, menuType) => {
    switch (menuType) {
      case "country":
        setCountry(option);
        setCity(null); // If country changes, cannot set city from previous country so reset.
        break;
      case "city":
        setCity(option);
        break;
      default:
        break;
    }
  };

  const navigate = useNavigate();

  const handleSignInSwap = () => {
    setPassword("");

    setFirstName("");
    setCompanyName("");
    setCheckedAgreeToTerms(false);
    setValidPassword(false);
    setValidEmail(false);

    if (pageMode === "invite") {
      setPageMode("login");
      return;
    }

    setEmail("");
    setPageMode(pageMode === "login" ? "signup" : "login");
  };

  const googleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider).then(async () => {
        const user = auth.currentUser;
        if (user && (await userExists())) {
          toast.error("User already exists. Please try again.");
          auth.signOut();
        } else {
          createAccount(true);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const createAccount = async (isGoogle = false, workspace, paymentPlan) => {
    return new Promise(async (resolve, reject) => {
      let uid;
      try {
        uid = isGoogle
          ? auth.currentUser.uid
          : (await createUserWithEmailAndPassword(auth, email, password)).user
              .uid;
      } catch (e) {
        setEmailInUse(true);
        reject(e);
        return;
      }

      const newDocRef = doc(collection(db, "users"), uid);

      const stateData = {
        pfp: "https://firebasestorage.googleapis.com/v0/b/ux-pro.appspot.com/o/users%2Fblack-logo.png?alt=media&token=e05881ad-fe52-4cbb-9cb5-0e366b03a8f6",
        email: email,
        paymentPlan: "start",
        createdAt: dateReactToFirestore(new Date()),
      };

      if (pageMode === "invite") {
        stateData.role = workspace.role;
        stateData.firstName = workspace.name;
        stateData.jobTitle = workspace.jobTitle;
        stateData.workspace = pid["pid"];
        stateData.paymentPlan = paymentPlan;
        stateData.needsToUpdateProjectWorkspace = true;
      }

      if (pageMode === "signup") {
        stateData.companyName = companyName;
        stateData.country = country.value;
        stateData.city = city.value;
        stateData.role = "client";
        stateData.awaitingApproval = true;

        if (isGoogle) {
          stateData.email = auth.currentUser.email; // Replace email if google
          stateData.firstName = auth.currentUser.displayName;
          stateData.isGoogleAccount = true;
        } else {
          stateData.firstName = firstName;
        }
      }

      try {
        await setDoc(newDocRef, stateData);

        if (pageMode === "signup") {
          createChatRoom(uid);
          newUserNotification(uid);
        }

        if (pageMode === "invite") {
          if (workspace.role !== "reader") {
            createChatRoom(uid);
          }
          newUserNotification(uid);
        }
        resolve(newDocRef);
      } catch (e) {
        reject(e);
      }
    });
  };

  const handleCreateAccountClick = async () => {
    setVerifying(true);
    if (pageMode === "signup") {
      createAccount(false, "client")
        .then(() => navigate("/create-project"))
        .catch((e) => {}); // Error already handled with text under email input
    } else if (pageMode === "invite") {
      const response = await getInvitedWorkspace({
        pid: pid["pid"],
        email: email,
      });

      if (response.status === 404) {
        setVerifying(false);
        return toast.error("Project not found!");
      }
      if (response.status === 401) {
        setVerifying(false);
        return toast.error("This email was not invited to the project!");
      }
      if (response.status === 202) {
        setVerifying(false);
        createAccount(false, response.workspaceUser, response.paymentPlan)
          .then(() => {
            try {
              navigate("/", { state: { updateWorkspace: true } });
            } catch (e) {
              console.error("Error signing in: ", e);
              toast.error("Error signing in. Please try again.");
            }
          })
          .catch((e) => {
            if (e.code === "auth/email-already-in-use") {
              toast.error(
                "Email already in use! If you have an account, please sign in normally or reset your password if you have forgotten!",
              );
            }
          });
      }
    }
  };

  useEffect(() => {
    if (pageMode === "signup") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      setEmailInUse(false);
      if (email) {
        setValidEmail(emailPattern.test(email));
      }
    }
  }, [email, companyName, pageMode]);

  const handlePasswordChange = (value) => {
    setPassword(value);

    const pass1cap = /[A-Z]/.test(value);
    const pass1num = /[0-9]/.test(value);
    const pass8char = value?.length >= 8;
    const pass1spec = /[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?]/.test(value);
    setPass1cap(pass1cap);
    setPass1num(pass1num);
    setPass8char(pass8char);
    setPass1spec(pass1spec);

    setValidPassword(pass1cap && pass1num && pass8char && pass1spec);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1100) {
      setStackVertical(false);
    } else {
      setStackVertical(true);
    }
  }, [windowWidth]);

  const handleKeyPress = (event) => {
    if (email && pageMode !== "reset" && password && event.key === "Enter")
      handleEmailAuth();
  };

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (e) {
      console.error(e);
    }
  };

  const handleEmailAuth = async () => {
    try {
      if (pageMode === "reset") {
        await sendPasswordResetEmail(auth, email);
        setBadCredentials(true);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/");
      }
    } catch (error) {
      setBadCredentials(true);
      setPassword("");
      if (pageMode === "reset") {
        setEmail("");
      }
    }
  };

  const PasswordValidation = ({ pass1cap, pass1num, pass8char, pass1spec }) => {
    return (
      <div className="mt-16 fs-14">
        <div>Your password should contain at least </div>
        <div className="mt-5">
          {pass1cap && <i className="fa-solid fa-check"></i>}
          <span className={pass1cap ? "validPass" : "invalidPass"}>
            1 capital letter
          </span>
        </div>
        <div className="mt-5">
          {pass1num && <i className="fa-solid fa-check"></i>}
          <span className={pass1num ? "validPass" : "invalidPass"}>
            1 number
          </span>
        </div>
        <div className="mt-5">
          {pass8char && <i className="fa-solid fa-check"></i>}
          <span className={pass8char ? "validPass" : "invalidPass"}>
            8 characters
          </span>
        </div>
        <div className="mt-5">
          {pass1spec && <i className="fa-solid fa-check"></i>}
          <span className={pass1spec ? "validPass" : "invalidPass"}>
            1 special character (~&#96;!@#$%^&*()_-+=&#91;&#93;|:;&quot;
            {"{,?.}"}&#39;&lt;&gt;)
          </span>
        </div>
      </div>
    );
  };

  const handleForgotPasswordClick = () => {
    setEmail("");
    setPassword("");
    setBadCredentials(false);
    setPageMode(pageMode === "reset" ? "login" : "reset");
  };

  const passwordResetPageToggle = (
    <div
      style={{ cursor: "pointer" }}
      className="forgot-password mb-2"
      onClick={handleForgotPasswordClick}
    >
      {pageMode === "reset" ? "Back" : "Forgot password?"}
    </div>
  );

  const badCredentialsIndicator = (
    <div
      className={`bad-credentials ${
        pageMode === "reset" ? "resetlink" : ""
      } mb-2`}
    >
      {badCredentials
        ? pageMode === "reset"
          ? "Password reset email sent."
          : "Invalid credentials. Try again."
        : ""}
    </div>
  );

  const emailInput = (
    <div className="mb-1">
      <CustomInput
        containerClassName="remove-input-restrictions observation-text-input-container v2-login-width"
        inputClassName="v2-login-width"
        placeholder="Email address"
        className={email === "" ? "active" : ""}
        valueState={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setBadCredentials(false);
        }}
        value={email}
      />
    </div>
  );

  return (
    <div className="sign-in-container">
      <div className="motto">
        <div className={stackVertical ? "motto-vertical" : "motto-horizontal"}>
          <div>
            <div className="motto-text fw-700">
              {pageMode === "signup"
                ? "You are smart. Take decision"
                : "It is a beautiful day to"}
            </div>
            <div className="motto-text fw-700">
              {pageMode === "signup"
                ? "on data, not on intuition."
                : "build a beautiful product."}
            </div>
          </div>
          {stackVertical && (
            <>
              <div className="column" style={{ position: "absolute", left: 0 }}>
                <img
                  src={fadedLogo}
                  alt="Image of logo faded into background"
                  style={{
                    width: "292.25px",
                    height: "536.29px",
                    display: "block",
                    marginTop: "43.75px",
                  }}
                />
              </div>

              <div
                style={{
                  marginTop: stackVertical ? "18.3vh" : "0px",
                  marginLeft: stackVertical ? "0px" : "50px",
                }}
              >
                <img
                  alt="Company logo"
                  src={logo}
                  style={{
                    width: "132px",
                    height: "150px",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
                <div
                  className="motto-text fw-900 fs-24"
                  style={{
                    marginTop: stackVertical ? "20px" : "5px",
                  }}
                >
                  LUXIFER
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "27vh",
                }}
                className="fw-400 fs-14 lh-24 fc-light-grey font-redhat"
              >
                © 2024 Luxifer. All rights reserved.
              </div>
            </>
          )}
        </div>
      </div>

      <div className="form" onKeyPress={handleKeyPress}>
        <div className="form-text">
          {pageMode === "signup" && (
            <>
              <div
                className="fw-500 signin-header"
                style={{ marginBottom: "37.47px" }}
              >
                Create your account for <span className="fw-700">free.</span>
              </div>

              <div className="v2-login-width">
                <CustomInput
                  placeholder="First name"
                  containerClassName="observation-text-input-container v2-login-width"
                  inputClassNames="v2-login-width"
                  className={firstName === "" ? "active" : ""}
                  valueState={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  maxLength={50}
                />
                <div style={{ position: "relative" }}>
                  {!/^\s*$/.test(firstName) && (
                    <img
                      alt="✓"
                      src={check}
                      className="check check-full-width-offset"
                    />
                  )}
                </div>
                <CustomInput
                  placeholder="Company name"
                  containerClassName="observation-text-input-container v2-login-width signup-input-gap"
                  inputClassNames="v2-login-width"
                  className={companyName === "" ? "active" : ""}
                  valueState={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  maxLength={50}
                />
                <div style={{ position: "relative" }}>
                  {!/^\s*$/.test(companyName) && (
                    <img
                      alt="✓"
                      src={check}
                      className="check check-full-width-offset"
                    />
                  )}
                </div>
                <CustomInput
                  placeholder="Professional email"
                  containerClassName="observation-text-input-container v2-login-width signup-input-gap"
                  inputClassNames="v2-login-width"
                  className={email === "" ? "active" : ""}
                  valueState={email}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <div className="err-msg fs-16">
                  {!validEmail &&
                    email !== "" &&
                    "Invalid email, must be xx@yy.zz format and contain company name."}
                  {emailInUse && "Email already in use"}
                </div>
                <div style={{ position: "relative" }}>
                  {validEmail && !emailInUse && (
                    <img
                      alt="✓"
                      src={check}
                      className="check check-full-width-offset"
                    />
                  )}
                </div>
                <div
                  style={{
                    minHeight: "46px",
                    marginTop: "16px",
                  }}
                  className="profile-width-input sign-up-location-container"
                >
                  <div className="row">
                    <CustomDropdown
                      options={countryOptions}
                      onChange={(option) => onSelect(option, "country")}
                      value={country}
                      placeholder="Country"
                      unstyled
                      menuOpenState={isCountryOpen}
                      setMenuOpenState={setIsCountryOpen}
                    />
                  </div>
                  <div
                    className="row"
                    style={{
                      marginTop: "6px", // This will turn into 16
                    }}
                  >
                    <CustomDropdown
                      options={cityOptions}
                      onChange={(option) => onSelect(option, "city")}
                      value={city}
                      placeholder="City"
                      unstyled
                      menuOpenState={isCityOpen}
                      setMenuOpenState={setIsCityOpen}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "-8px" }} className="d-block">
                  <CustomInput
                    placeholder="Password"
                    containerClassName="observation-text-input-container v2-login-width signup-input-gap"
                    inputClassNames="v2-login-width"
                    className={password === "" ? "active" : ""}
                    valueState={password}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    value={password}
                    type="password"
                  />
                  <div style={{ position: "relative" }}>
                    {validPassword && (
                      <img
                        alt="✓"
                        src={check}
                        className="check check-full-width-offset"
                      />
                    )}
                  </div>
                  <PasswordValidation
                    pass1cap={pass1cap}
                    pass1num={pass1num}
                    pass8char={pass8char}
                    pass1spec={pass1spec}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  marginTop: "28px",
                }}
                className="row mb-3"
              >
                <label className="d-flex" style={{ cursor: "pointer" }}>
                  <input
                    type="checkbox"
                    checked={checkedAgreeToTerms}
                    onChange={() =>
                      setCheckedAgreeToTerms(!checkedAgreeToTerms)
                    }
                    className="checkbox-input"
                  />

                  <span
                    style={{ marginLeft: "15.88px" }}
                    className="lh-189 fs-14 fw-500"
                  >
                    I read and I accept{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://luxifer.digitalrise.be/conditions-dutilisation/"
                      className="bold styled-link fc-black-2"
                    >
                      general terms and conditions of use.
                    </a>
                  </span>
                </label>
              </div>

              <button
                className={
                  !firstName ||
                  !companyName ||
                  !validEmail ||
                  !validPassword ||
                  !checkedAgreeToTerms ||
                  !country?.value ||
                  !city?.value
                    ? "email-auth mt-4 v2-login-width"
                    : "email-auth-active mt-4 v2-login-width"
                }
                onClick={handleCreateAccountClick}
                disabled={
                  !firstName ||
                  !companyName ||
                  !validEmail ||
                  !validPassword ||
                  !checkedAgreeToTerms ||
                  !country?.value ||
                  !city?.value
                }
              >
                <div className="email-auth-text">
                  Sign up
                  <img src={arrowRight} alt="->" width="17.8" height="17.8" />
                </div>
              </button>

              {/* GOOGLE - re-add based on client design
              <button
                className="google-sign-in mt-3 v2-login-width"
                onClick={() => googleSignUp()}
                disabled={!firstName || !companyName || !checkedAgreeToTerms}
              >
                <div className="google-sign-in-text">
                  <img src={googleLoginIcon} alt="G" width="20" height="20" />
                  Sign up with Google
                </div>
              </button>
              */}
              <div className="sign-up">
                Already have an account,&nbsp;
                <span
                  className="bold styled-link fc-black-2"
                  onClick={() => handleSignInSwap()}
                >
                  Sign in.
                </span>
              </div>
            </>
          )}
          <div className="signin-header">
            <span className="bold">
              {pageMode === "reset"
                ? "Forgot password?"
                : pageMode === "signup"
                  ? ""
                  : "Welcome."}
            </span>
            <br></br>
            {pageMode === "reset"
              ? "Please enter your email."
              : pageMode === "signup"
                ? ""
                : pageMode === "login"
                  ? "Connect to LUXIFER."
                  : "You have been invited to LUXIFER."}
          </div>

          {pageMode === "reset" && (
            <div style={{ width: 505 }} className="mt-5">
              {emailInput}

              <button
                className={
                  !email ? "email-auth my-2" : "email-auth-active  my-2"
                }
                onClick={handleEmailAuth}
                disabled={!email}
              >
                <div className="email-sign-in-text">Reset password</div>
              </button>
              <div style={{ padding: "0px 12px" }}>
                {badCredentialsIndicator}
                {passwordResetPageToggle}
              </div>
            </div>
          )}

          {pageMode === "login" && (
            <div>
              <div className="mt-5 v2-login-width">
                {emailInput}
                <CustomInput
                  style={{ backgroundColor: "red !important" }}
                  placeholder="Password"
                  containerClassName="remove-input-restrictions observation-text-input-container"
                  className={password === "" ? "active" : ""}
                  valueState={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setBadCredentials(false);
                  }}
                  value={password}
                  type="password"
                />
              </div>
              <div style={{ padding: "0px 12px" }}>
                {badCredentialsIndicator}
                {passwordResetPageToggle}
              </div>
              <button
                className={
                  !email || !password
                    ? "email-auth mt-4 v2-login-width"
                    : "email-auth-active mt-4 v2-login-width"
                }
                onClick={handleEmailAuth}
                disabled={!email || !password}
              >
                <div className="email-auth-text">
                  Login
                  <img src={arrowRight} alt="->" width="17.8" height="17.8" />
                </div>
              </button>

              {/* GOOGLE - re-add based on client design
               <button
                className="google-sign-in mt-3 v2-login-width"
                onClick={googleSignIn}
              >
                <div className="google-sign-in-text">
                  <img src={googleLoginIcon} alt="G" width="20" height="20" />
                  Login with Google
                </div>
              </button>
              */}
              <div className="sign-up">
                Don't have an account yet?&nbsp;
                <span
                  className="bold styled-link fc-blue"
                  onClick={() => handleSignInSwap()}
                >
                  Sign up.
                </span>
              </div>
            </div>
          )}

          {pageMode === "invite" && (
            <div>
              <div className="v2-login-width mt-5">
                <div className="fw-500 fc-black fs-18">choose a password</div>
                <div className="d-block">
                  <CustomInput
                    placeholder="Password"
                    containerClassName="observation-text-input-container v2-login-width signup-input-gap"
                    inputClassNames="v2-login-width"
                    className={password === "" ? "active" : ""}
                    valueState={password}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    value={password}
                    type="password"
                  />
                  <div style={{ position: "relative" }}>
                    {validPassword && (
                      <img
                        alt="✓"
                        src={check}
                        className="check check-full-width-offset"
                      />
                    )}
                  </div>
                  <CustomInput
                    placeholder="Confirm Password"
                    containerClassName="observation-text-input-container v2-login-width signup-input-gap"
                    inputClassNames="v2-login-width"
                    className={confirmPassword === "" ? "active" : ""}
                    valueState={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    type="password"
                  />
                  <div className="err-msg fs-16">
                    {password !== confirmPassword &&
                      confirmPassword !== "" &&
                      "Passwords do not match"}
                  </div>
                  <div style={{ position: "relative" }}>
                    {password === confirmPassword && confirmPassword !== "" && (
                      <img
                        alt="✓"
                        src={check}
                        className="check check-full-width-offset"
                      />
                    )}
                  </div>
                  <PasswordValidation
                    pass1cap={pass1cap}
                    pass1num={pass1num}
                    pass8char={pass8char}
                    pass1spec={pass1spec}
                  />
                  {/* <div className="strength-bar-fix">
                    <PasswordStrengthBar
                      password={password}
                      style={{ marginTop: "26px", width: "110px" }}
                      scoreWordStyle={{
                        color: "black",
                        transform: "translate(129px, -20px)",
                        fontSize: "14px",
                        fontWeight: "700",
                        textAlign: "left",
                      }}
                      minLength={6}
                      scoreWords={[
                        "weak",
                        "okay",
                        "good",
                        "strong",
                        "very strong",
                      ]}
                      barColors={["#ddd", "black", "black", "black", "black"]}
                    />
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      marginTop: "28px",
                    }}
                    className="row mb-3"
                  >
                    <label className="d-flex" style={{ cursor: "pointer" }}>
                      <input
                        type="checkbox"
                        checked={checkedAgreeToTerms}
                        onChange={() =>
                          setCheckedAgreeToTerms(!checkedAgreeToTerms)
                        }
                        className="checkbox-input"
                      />

                      <span
                        style={{ marginLeft: "15.88px" }}
                        className="lh-189 fs-14 fw-500"
                      >
                        I read and I accept{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://luxifer.digitalrise.be/conditions-dutilisation/"
                          className="bold styled-link fc-black-2"
                        >
                          general terms and conditions of use.
                        </a>
                      </span>
                    </label>
                  </div>
                  <button
                    className={
                      !checkedAgreeToTerms ||
                      !validPassword ||
                      password !== confirmPassword ||
                      verifying
                        ? "email-auth mt-4 v2-login-width"
                        : "email-auth-active mt-4 v2-login-width"
                    }
                    onClick={handleCreateAccountClick}
                    disabled={
                      !checkedAgreeToTerms ||
                      !validPassword ||
                      password !== confirmPassword ||
                      verifying
                    }
                  >
                    <div className="email-auth-text">
                      {verifying ? (
                        <Spinner style={{ height: "30px", width: "30px" }} />
                      ) : (
                        <>
                          Sign up
                          <img
                            src={arrowRight}
                            alt="->"
                            width="17.8"
                            height="17.8"
                          />
                        </>
                      )}
                    </div>
                  </button>
                </div>
              </div>
              <div style={{ paddingBottom: "100px" }}>
                <div className="sign-up">
                  Already have an account?&nbsp;
                  <span
                    className="bold styled-link fc-blue"
                    onClick={() => handleSignInSwap()}
                  >
                    Sign in.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function SignIn() {
  return (
    <>
      <ToastContainer autoClose={20000} />
      <SignInContent />
    </>
  );
}
