import { useContext } from "react";
import "../ClientReports/ClientReports.css";
import "./InsightsV3Container.css";
import { Link, Outlet } from "react-router-dom";
import { UserInfoContext } from "../../contexts";
import { FiArrowLeft } from "react-icons/fi";
import NotificationPopup from "../NotificationPopup/NotificationPopup";

export function InsightsV3Container(props) {
  const userInfo = useContext(UserInfoContext);

  return (
    <div
      className="client-reports-parent"
      style={{ marginLeft: "36px", marginTop: "39px" }}
    >
      <div style={{ position: "absolute", top: "32px", right: "32px" }}>
        <div className="d-flex justify-content-end align-items-center">
          <NotificationPopup
            setActiveMenuItem={props.setActiveMenuItem}
            userInfo={userInfo}
          />
        </div>
      </div>

      {userInfo?.role === "admin" && (
        <Link to={"/"}>
          <div
            style={{
              width: "200px",
              height: "24px",
              textAlign: "center",
              marginTop: "-50px",
              paddingBottom: "50px",
            }}
            className="fs-14 fw-700 fc-black mb-2"
          >
            <FiArrowLeft
              color="#000"
              size="24px"
              style={{ marginRight: "10px", marginBottom: "2px" }}
            />
            Back to Admin Panel
          </div>
        </Link>
      )}

      <Outlet />
    </div>
  );
}
