import React from "react";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import "./Legal.css";

function LegalContent() {
  return (
    <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
      <div className="legal-main-content">
        <div style={{ marginLeft: "50.36px" }}>
          <div style={{ marginTop: "35.5px" }}>
            <Link to="/">
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                }}
                className="fs-14 fw-700 fc-black"
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </Link>
            <div
              style={{ width: "194px", height: "24px", marginTop: "61.55px" }}
              className="fs-24 fw-700 lh-243 "
            >
              Legal
            </div>
            <div
              style={{
                maxWidth: "651.64px",
                marginTop: "12px",
                marginBottom: "32px",
              }}
            >
              <div className="fs-16 fw-500 lh-20 fc-grey lc-023">
                <p>Find all our legal documents and resources.</p>
              </div>
            </div>
            <ul className="legal-list">
              <li className="legal-list-item">
                <a
                  href="https://google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="legal-link fc-blue"
                >
                  Privacy policies
                  <i className="fa-regular fa-arrow-up-right-from-square fc-blue"></i>
                </a>
              </li>
              <li className="legal-list-item">
                <a
                  href="https://google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="legal-link fc-blue"
                >
                  Cookies policy
                  <i className="fa-regular fa-arrow-up-right-from-square fc-blue"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Legal(props) {
  return (
    <>
      <LegalContent />
    </>
  );
}
