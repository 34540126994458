import Dashboard from "../components/Dashboard/Dashboard";
import MainLayout from "../components/MainLayout";
import { SignIn } from "./SignIn/SignIn";
import { RTDB, auth, db } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { InProgress } from "./InProgress/InProgress";
import { ClientReports } from "./ClientReports/ClientReports";
import Chatbar from "./Chatbar/Chatbar";
import {
  getUserChannels,
  getUserInformation,
  getUserProjects,
  hardCodedRules7_1_1and7_1_2,
  subscribeToUserChannels,
  userExists,
  firebaseLogout,
} from "../helpers";
import { useEffect, useState } from "react";
import { Insights } from "./Insights/Insights";
import { Reports } from "./Reports/Reports";
import { Report } from "./Report/Report";
import { Todo } from "./Todo/Todo";
import CreateReport from "./CreateReport/CreateReport";
import { DesignerReports } from "./DesignerReports/DesignerReports";
import AdminManageInsights from "./AdminManageInsights/AdminManageInsights";
import { Invite } from "./Invite/Invite";
import Contract from "./Contract/Contract";
import {
  SelectedProjectContext,
  ProjectsContext,
  UserInfoContext,
} from "../contexts";
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";
import logoWhite from "../assets/white-logo.png";
import validateStare1 from "../assets/validate-stare1.png";
import validateStare2 from "../assets/validate-stare2.png";
import TagManager from "./TagManager/TagManager";
import { InsightsV3 } from "./InsightsV3/InsightsV3";
import { InsightsV3Container } from "./InsightsV3Container/InsightsV3Container";
import Profile from "./Profile/Profile";
import ManageProjects from "./ManageProjects/ManageProjects";
import ManageNotifications from "./ManageNotifications/ManageNotifications";
import PlanAndBillings from "./PlanAndBillings/PlanAndBillings";
import Legal from "./Legal/Legal";
import Authorizations from "./Authorizations/Authorizations";
import Preferences from "./Preferences/Preferences";
import FixIt from "./Fixit/Fixit";
import { ref, set, onValue, onDisconnect } from "firebase/database";
import FunnelAnalysis from "./FunnelAnalysis/FunnelAnalysis";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import AdminManagePayment from "./AdminManagePayment/AdminManagePayment";
import AdminAccessPlans from "./AdminAccessPlans/AdminAccessPlans";
import GlobalStyleCursor from "../GlobalStyleCursor";
import AdminMessages from "./AdminMessages/AdminMessages";

export default function Root() {
  const [user, loading] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState({});
  const [isChatbarOpen, setIsChatbarOpen] = useState(false);
  const [openedChannel, setOpenedChannel] = useState(null);
  const [userChannels, setUserChannels] = useState([]);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [selectedProject, setSelectedProject] = useState({});
  const [projects, setProjects] = useState(null);

  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const [chatPartnerStatus, setChatPartnerStatus] = useState("online");
  const [onlineChats, setOnlineChats] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(location.search),
  );
  const [room, setRoom] = useState(searchParams.get("room"));

  const [adminPage, setAdminPage] = useState("manage");

  const [heatmapFunnelFilters, setHeatmapFunnelFilters] = useState({});

  const [googleAccountBeingCreated, setGoogleAccountBeingCreated] =
    useState(false);

  const buildRTDBPresence = (uid) => {
    const userStatusFirestoreRef = doc(db, "/status/" + uid);

    const isOfflineForFirestore = { state: "offline" };
    const isOnlineForFirestore = { state: "online" };

    const connectedRef = ref(RTDB, ".info/connected");
    onValue(connectedRef, (snapshot) => {
      if (snapshot.val() === false) {
        setDoc(userStatusFirestoreRef, isOfflineForFirestore);
        return;
      }
      const userStatusDatabaseRef = ref(RTDB, "/status/" + uid);
      onDisconnect(userStatusDatabaseRef)
        .set(isOfflineForFirestore)
        .then(() => {
          set(userStatusDatabaseRef, isOnlineForFirestore);
          setDoc(userStatusFirestoreRef, isOnlineForFirestore);
        });
    });
  };

  const [unreadMessagesArr, setUnreadMessagesArr] = useState([]);

  const [isFunnel, setIsFunnel] = useState(false);
  const [hideFunnelFilters, setHideFunnelFilters] = useState(false);
  /**
   * Get user information, channel, and projects if the user exists. Otherwise, sign out (Incomplete account).
   */
  useEffect(() => {
    let unsubscribeChannels = null;
    const getInfo = async () => {
      try {
        if (user) {
          // User may not exist if still being created. Retry in 2 seconds.
          if (!(await userExists()) && !googleAccountBeingCreated) {
            setTimeout(() => getInfo(), 3000);
            return;
          }
          const userInformation = await getUserInformation();
          setUserInfo(userInformation);
          setUserChannels(await getUserChannels(userInformation));
          unsubscribeChannels = subscribeToUserChannels(
            userInformation,
            setUnreadMessagesArr,
          );
          const userProjects = await getUserProjects(userInformation);

          // Prepare data for Bounce rate and Engagement queries
          const ga4Projects = userProjects.filter(
            (project) => project.platform === "GA4",
          );
          hardCodedRules7_1_1and7_1_2(ga4Projects);

          // Build sidebar list of projects
          if (userInformation.role === "client") {
            setProjects([...userProjects]);
          } else {
            setProjects([...userProjects]);
          }
          setSelectedProject(userProjects[0]);

          buildRTDBPresence(userInformation.uid);
        }
      } catch (e) {}
    };

    if (!googleAccountBeingCreated) getInfo();

    return () => {
      if (unsubscribeChannels) {
        unsubscribeChannels();
      }
    };
  }, [user]);

  useEffect(() => {
    if (userChannels) {
      const combinedParties = new Set();
      userChannels.forEach((channel) => {
        channel.parties.forEach((party) => {
          combinedParties.add(party);
        });
      });
      combinedParties.delete(userInfo.uid);
      const unsubscribeFunctions = [];

      combinedParties.forEach((partyId) => {
        const unsubscribe = onSnapshot(
          doc(db, "status", partyId),
          (docSnapshot) => {
            const partyStatus = docSnapshot.data()?.state;
            setOnlineChats((currentOnlineChats) => {
              const isOnline = partyStatus === "online";
              const isAlreadyIncluded = currentOnlineChats.includes(partyId);

              if (isOnline && !isAlreadyIncluded) {
                return [...currentOnlineChats, partyId];
              } else if (!isOnline && isAlreadyIncluded) {
                return currentOnlineChats.filter((id) => id !== partyId);
              } else {
                return currentOnlineChats;
              }
            });
          },
        );

        unsubscribeFunctions.push(unsubscribe);
      });

      return () => {
        unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
      };
    }
  }, [userChannels]);

  useEffect(() => {
    if (onlineChats && openedChannel?.parties) {
      const partnerId = openedChannel.parties.filter(
        (party) => party !== userInfo.uid,
      )[0];

      setChatPartnerStatus(
        onlineChats.includes(partnerId) ? "online" : "offline",
      );
    }
  }, [onlineChats, openedChannel]);

  function handleChannelClick(channel) {
    setOpenedChannel(channel);
    navigate("/chat");
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSearchParams(params);
    const adminId = "ERUhn7JKx6QLp3flbFAAJo7e7E92";
    setRoom(params.get("room"));

    if (userChannels.length > 0 && params.get("room") == "admin") {
      if (auth && auth.currentUser?.uid === adminId) {
        navigate("/messages");
      }

      const adminChannel = userChannels.find(
        (channel) => channel.parties && channel.parties.includes(adminId),
      );

      if (adminChannel) {
        setOpenedChannel(adminChannel);
      }
    }
  }, [userChannels.length, location]);

  /**
   * If the page is loading, or user info is loading, the Spinning wheel will be shown.
   */
  if (loading || (user && !userInfo.role)) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GlobalStyleCursor />
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (user && userInfo.awaitingApproval) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal
          appElement={document.getElementById("root") || undefined}
          isOpen={true}
          className="wait-for-validate-modal"
          style={{
            overlay: {
              background: "rgba(48, 48, 63, 1)",
              backdropFilter: "blur(8px)",
              zIndex: 10,
            },
          }}
        >
          <div className="column" style={{ position: "absolute", left: 0 }}>
            <img
              src={validateStare1}
              alt="logo faded into background"
              style={{
                width: "228.5px",
                height: "380px",
                display: "block",
                marginTop: "11.2vh",
              }}
            />
          </div>
          <div className="column">
            <div className="row">
              <img
                src={logoWhite}
                alt="white logo"
                style={{
                  width: "160px",
                  height: "32px",
                  display: "block",
                  margin: "0 auto",
                  marginTop: "38.4vh",
                }}
                onClick={async () => {
                  await firebaseLogout(userInfo.uid);
                  if (auth) {
                    await auth.signOut();
                  }
                  navigate("/");
                }}
              ></img>
            </div>
            <div
              className="row fc-white fs-42"
              style={{
                display: "block",
                margin: "0 auto",
                marginTop: "77.61px",
                lineHeight: "20.13px",
              }}
            >
              {userInfo.hidden
                ? "Your account was declined"
                : "Everything is done here."}
            </div>
            <div
              className="row fc-white fs-18"
              style={{
                width: "379.6px",
                display: "block",
                margin: "0 auto",
                marginTop: "45.8px",
                lineHeight: "24.3px",
                textAlign: "center",
              }}
            >
              {userInfo.hidden
                ? "Please contact support"
                : " Please wait while we approve your account."}
            </div>
            <div
              style={{
                margin: "0 auto",
                marginTop: "32px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="btn-senary"
                onClick={() =>
                  window.open(
                    "https://luxifer.digitalrise.be/help/",
                    "_blank",
                    "noopener,noreferrer",
                  )
                }
              >
                Read the FAQ in the meantime
              </button>
            </div>
          </div>
          <div className="column" style={{ position: "absolute", right: 0 }}>
            <img
              src={validateStare2}
              alt="logo faded into background"
              style={{
                width: "228.5px",
                height: "380px",
                display: "block",
                marginTop: "30.3vh",
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }

  const paths = [
    "/manage-projects",
    "/profile",
    "/notifications",
    "/manage-your-team",
    "/authorizations",
    "/preferences",
    "/plan-and-billings",
    "/legal",
  ];
  const hideSidebar = paths.some((path) => location.pathname.includes(path));
  const profileView = paths.some((path) => location.pathname.includes(path));

  if (user) {
    return (
      <ProjectsContext.Provider value={projects}>
        <SelectedProjectContext.Provider value={selectedProject}>
          <UserInfoContext.Provider value={userInfo}>
            <ToastContainer />
            <MainLayout
              sidebarCollapsed={sidebarCollapsed}
              setSidebarCollapsed={setSidebarCollapsed}
              activeMenuItem={activeMenuItem}
              setActiveMenuItem={setActiveMenuItem}
              setSelectedProject={setSelectedProject}
              userChannels={userChannels}
              openedChannel={openedChannel}
              setOpenedChannel={setOpenedChannel}
              handleChannelClick={handleChannelClick}
              setUserInfo={setUserInfo}
              profileView={profileView}
              hideSidebar={hideSidebar}
              onlineChats={onlineChats}
              unreadMessagesArr={unreadMessagesArr}
              setUserChannels={setUserChannels}
              isFunnel={isFunnel}
              hideFunnelFilters={hideFunnelFilters}
              setHideFunnelFilters={setHideFunnelFilters}
              setAdminPage={setAdminPage}
              setHeatmapFunnelFilters={setHeatmapFunnelFilters}
            >
              <GlobalStyleCursor />
              <Routes>
                <Route
                  index
                  element={
                    ["client", "owner", "reader", "editor"].includes(
                      userInfo.role,
                    ) ? (
                      <Dashboard setActiveMenuItem={setActiveMenuItem} />
                    ) : userInfo.role === "designer" ? (
                      <Todo />
                    ) : userInfo.role === "admin" ? (
                      <AdminAccessPlans setActiveMenuItem={setActiveMenuItem} />
                    ) : userInfo.role === "tagManager" ? (
                      <TagManager />
                    ) : (
                      <div>Unauthorized</div>
                    )
                  }
                />

                {["client", "owner", "reader", "editor", "admin"].includes(
                  userInfo.role,
                ) && (
                  <Route
                    path="/insights"
                    element={
                      <InsightsV3Container
                        setActiveMenuItem={setActiveMenuItem}
                      />
                    }
                  >
                    <Route
                      path="view"
                      element={
                        <InsightsV3
                          projects={projects}
                          setSelectedProject={setSelectedProject}
                        />
                      }
                    />
                  </Route>
                )}

                {["client", "owner", "reader", "editor", "admin"].includes(
                  userInfo.role,
                ) && (
                  <Route
                    path="/funnels"
                    element={
                      <InsightsV3Container
                        setActiveMenuItem={setActiveMenuItem}
                      />
                    }
                  >
                    <Route
                      path="view"
                      element={
                        <FunnelAnalysis
                          setIsFunnel={setIsFunnel} // decides if filter tab shows
                          setActiveMenuItem={setActiveMenuItem}
                          setHideFunnelFilters={setHideFunnelFilters}
                          selectedProject={selectedProject}
                          heatmapFunnelFilters={heatmapFunnelFilters}
                        />
                      }
                    />
                  </Route>
                )}

                {["client", "owner", "reader", "editor", "admin"].includes(
                  userInfo.role,
                ) && (
                  <Route
                    path="/fixit"
                    element={<FixIt setActiveMenuItem={setActiveMenuItem} />}
                  />
                )}

                {["admin"].includes(userInfo.role) && (
                  <>
                    <Route
                      path="/manage-payment-plans"
                      element={
                        <AdminManagePayment
                          setActiveMenuItem={setActiveMenuItem}
                        />
                      }
                    />
                    <Route
                      path="/manage-insights"
                      element={
                        <AdminManageInsights
                          setActiveMenuItem={setActiveMenuItem}
                          setSelectedProject={setSelectedProject}
                          setAdminPage={setAdminPage}
                          adminPage={adminPage}
                        />
                      }
                    />
                    <Route
                      path="/messages"
                      element={
                        <AdminMessages
                          handleChannelClick={handleChannelClick}
                          setActiveMenuItem={setActiveMenuItem}
                        />
                      }
                    />
                  </>
                )}

                {["client", "owner", "reader", "editor", "admin"].includes(
                  userInfo.role,
                ) && (
                  <>
                    <Route
                      path="/profile"
                      element={<Profile setUserInfo={setUserInfo} />}
                    />
                    <Route
                      path="/manage-projects"
                      element={<ManageProjects />}
                    />
                    <Route
                      path="/notifications"
                      element={<ManageNotifications />}
                    />
                    <Route
                      path="/authorizations"
                      element={<Authorizations />}
                    />
                    <Route path="/preferences" element={<Preferences />} />
                    <Route
                      path="/plan-and-billings"
                      element={<PlanAndBillings />}
                    />
                    <Route path="/legal" element={<Legal />} />
                  </>
                )}

                {["owner", "reader", "editor"].includes(userInfo.role) && (
                  <Route
                    path="/invited/:pid"
                    element={
                      <SignIn
                        setUserInfo={setUserInfo}
                        setSelectedProject={setSelectedProject}
                      />
                    }
                  />
                )}

                {["client"].includes(userInfo.role) && (
                  <Route
                    path="/invited/:pid"
                    element={
                      <Invite
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        setSelectedProject={setSelectedProject}
                      />
                    }
                  />
                )}

                {["client", "owner", "reader", "editor"].includes(
                  userInfo.role,
                ) ? (
                  <Route path="/reports" element={<ClientReports />}>
                    <Route path="insights" element={<Insights />} />
                    <Route path="reports" element={<Reports />} />
                  </Route>
                ) : userInfo.role === "designer" ? (
                  <Route path="/reports" element={<DesignerReports />} />
                ) : (
                  <Route path="/reports" element={<div>Unauthorized</div>} />
                )}
                {userInfo.role === "designer" && (
                  <Route path="/opportunities" element={<Todo />} />
                )}
                {(userInfo.role === "designer" ||
                  userInfo.role === "admin") && (
                  <Route
                    path="/create-report/:reportId"
                    element={
                      <CreateReport sidebarCollapsed={sidebarCollapsed} />
                    }
                  />
                )}
                {userInfo.role === "designer" && (
                  <Route path="/contract/:projectId" element={<Contract />} />
                )}
                <Route
                  path="/chat"
                  element={
                    <Chatbar
                      userChannels={userChannels}
                      openedChannel={openedChannel}
                      setOpenedChannel={setOpenedChannel}
                      handleChannelClick={handleChannelClick}
                      isChatbarOpen={isChatbarOpen}
                      setIsChatbarOpen={setIsChatbarOpen}
                      setActiveMenuItem={setActiveMenuItem}
                      chatPartnerStatus={chatPartnerStatus}
                      room={room}
                    />
                  }
                />

                <Route path="/report/:id" element={<Report />} />
                <Route path="/in-progress" element={<InProgress />} />
                <Route
                  path="/invite/:pid"
                  element={
                    <Invite
                      setUserInfo={setUserInfo}
                      setSelectedProject={setSelectedProject}
                    />
                  }
                />
                <Route path="*" element={<div>404 not found</div>} />
              </Routes>
            </MainLayout>
          </UserInfoContext.Provider>
        </SelectedProjectContext.Provider>
      </ProjectsContext.Provider>
    );
  } else {
    return (
      <>
        <GlobalStyleCursor />
        <Routes>
          <Route
            path="/invited/:pid"
            element={
              <SignIn
                setGoogleAccountBeingCreated={setGoogleAccountBeingCreated}
                setUserInfo={setUserInfo}
                setSelectedProject={setSelectedProject}
              />
            }
          />
          <Route
            path="/"
            element={
              <SignIn
                setGoogleAccountBeingCreated={setGoogleAccountBeingCreated}
                setUserInfo={setUserInfo}
                setSelectedProject={setSelectedProject}
              />
            }
          />
          <Route
            path="/invite/:pid"
            element={
              <Invite
                setUserInfo={setUserInfo}
                setSelectedProject={setSelectedProject}
              />
            }
          />
          <Route
            path="*"
            element={
              <SignIn
                setGoogleAccountBeingCreated={setGoogleAccountBeingCreated}
                setUserInfo={setUserInfo}
                setSelectedProject={setSelectedProject}
              />
            }
          />
        </Routes>
      </>
    );
  }
}
