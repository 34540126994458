import { useEffect, useState } from "react";
import CustomInput from "../CustomInput/CustomInput";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import "./AdminAccessPlans.css";
import { Spinner } from "react-bootstrap";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import {
  AdminTable,
  cascadePaymentplanToWorkspace,
  dateFirestoreToReact,
  enqueueAnalysisGuarded,
  formatDateDDMMMYY,
  getClientsWaitingForApproval,
  getExistingClientsForAdmin,
  updateProjectApproval,
  updateUserApproval,
  uppercase1stLetter,
} from "../../helpers";
import { ToastContainer, toast } from "react-toastify";

export default function AdminAccessPlans(props) {
  const [user, loading] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("");

  const [projects, setProjects] = useState(null);
  const [filteredProjects, setFilteredProjects] = useState(null);

  const [awaitingApprovals, setAwaitingApprovals] = useState(null);

  const [activeTab, setActiveTab] = useState("Exisiting clients");

  const [paymentPlanOptions, setPaymentPlanOptions] = useState([
    { label: "Start", value: "start" },
    { label: "Ultra", value: "ultra" },
    { label: "Corporate", value: "corporate" },
  ]);

  useEffect(() => {
    props.setActiveMenuItem("approveAnalysis");
  }, []);

  const tabItems = [
    {
      label: "Exisiting clients",
    },
    {
      label: "Awaiting approval",
    },
  ];

  function handleStateChange(value, field, type, id, setter) {
    setter((prevList) =>
      prevList.map((states, j) => {
        if (
          (type === "id" && states.id === id) ||
          (type === "index" && j === id)
        ) {
          return {
            ...states,
            [field]: value,
          };
        }
        return states;
      }),
    );
  }

  async function populateExisting() {
    let projects = await getExistingClientsForAdmin();
    if (projects.length === 0) {
      setIsLoading(false);
      return;
    }

    if (activeTab === "Awaiting approval") {
      let clients = await getClientsWaitingForApproval();
      clients = clients.map((client) => ({
        ...client,
        type: "client",
        clientEmail: client.email,
        clientCompany: client.companyName,
        clientFirstName: client.firstName,
        clientPaymentPlan: client.paymentPlan,
        clientId: client.id,
        role: client.role,
        clientAwaitingApproval: client.awaitingApproval,
      }));

      projects = projects.filter(
        (p) => p.awaitingAnalysis || p.awaitingApproval,
      );

      projects = [...clients, ...projects];
    }

    let projectsWithTableAttributes = projects.map((p, i) => {
      return {
        ...p,
        plan: {
          label: uppercase1stLetter(p.clientPaymentPlan ?? p.paymentPlan),
          value: p.clientPaymentPlan ?? p.paymentPlan,
        },
        expanded: false,
        dropdownOpen: false,
        planLoading: false,
      };
    });

    if (activeTab === "Awaiting approval") {
      setAwaitingApprovals(projectsWithTableAttributes);
    } else if (activeTab === "Exisiting clients") {
      setProjects(projectsWithTableAttributes);
      setFilteredProjects(projectsWithTableAttributes);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      populateExisting();
    }
  }, [user, activeTab]);

  async function updateUserPlan(uid, plan) {
    return new Promise(async (resolve, reject) => {
      try {
        const userRef = doc(db, "users", uid);
        await updateDoc(userRef, {
          paymentPlan: plan,
        });
        const count = await cascadePaymentplanToWorkspace(uid, plan);
        toast.success(`Client and ${count} invited users are now: ${plan}`);
        resolve();
      } catch (e) {
        toast.error("Failed to update user plans");
        reject();
      }
    });
  }

  async function changePaymentPlan(plan, project) {
    // Update projects unfiltered
    const jarr = projects.reduce((acc, p, index) => {
      if (p.clientId === project.clientId) {
        acc.push(index);
      }
      return acc;
    }, []);

    jarr.forEach((j) => {
      handleStateChange(
        true,
        "planLoading",
        "id",
        projects[j].id,
        setFilteredProjects,
      );

      handleStateChange(
        plan,
        "plan",
        "id",
        projects[j].id,
        setFilteredProjects,
      );
    });

    await updateUserPlan(project.clientId, plan.value);

    jarr.forEach((j) => {
      handleStateChange(
        false,
        "planLoading",
        "id",
        projects[j].id,
        setFilteredProjects,
      );
    });
    return true;
  }

  async function changePaymentPlanAwaiting(plan, project) {
    // Update projects unfiltered
    const jarr = awaitingApprovals.reduce((acc, p, index) => {
      if (p.clientId === project.clientId) {
        acc.push(index);
      }
      return acc;
    }, []);

    jarr.forEach((j) => {
      handleStateChange(
        true,
        "planLoading",
        "id",
        awaitingApprovals[j].id,
        setAwaitingApprovals,
      );

      handleStateChange(
        plan,
        "plan",
        "id",
        awaitingApprovals[j].id,
        setAwaitingApprovals,
      );
    });

    await updateUserPlan(project.clientId, plan.value);

    jarr.forEach((j) => {
      handleStateChange(
        false,
        "planLoading",
        "id",
        awaitingApprovals[j].id,
        setAwaitingApprovals,
      );
    });
    return true;
  }

  const handleFilterChange = (value) => {
    setFilter(value);
    setFilteredProjects(
      projects.filter((p) => {
        const valueLower = value.toLowerCase();
        const clientEmailMatch = p?.clientEmail
          ?.toLowerCase()
          .includes(valueLower);
        const nameMatch = p?.name?.toLowerCase().includes(valueLower);
        const workspaceEmailMatch = p?.workspace?.some((w) =>
          w.email?.toLowerCase().includes(valueLower),
        );

        return clientEmailMatch || nameMatch || workspaceEmailMatch;
      }),
    );
  };

  async function updateProjectsAfterApproval(project) {
    await updateProjectApproval(project.id, false);

    const projectsAfter = awaitingApprovals.filter((p) => p !== project);

    setAwaitingApprovals(projectsAfter);
  }

  async function updateClientAfterApproval(item, status) {
    updateUserApproval(item.clientId, status);

    let itemsAfter;
    if (status) {
      // Reject client
      itemsAfter = awaitingApprovals.filter((p) => p.id !== item.id);
    } else {
      // Approve client
      itemsAfter = awaitingApprovals.filter((c) => c !== item);
    }

    setAwaitingApprovals(itemsAfter);
  }

  async function handleApproveClick(project) {
    await updateProjectsAfterApproval(project);
    enqueueAnalysisGuarded(project.id);
    toast.success("Analysis Approved!");
  }

  async function handleDenyClick(project) {
    await updateProjectsAfterApproval(project);
    toast.success("Analysis Denied!");
  }

  async function handleClientApproveClick(project) {
    await updateClientAfterApproval(project, false); // False means it's approved
    toast.success("Client Approved!");
  }

  async function handleClientDenyClick(project) {
    // If you decline a client, their projects autodecline.
    await updateClientAfterApproval(project, true); // True means it does not get approved
    toast.success("Client Denied!");
  }

  const existingWidths = [230, 250, 160, 140, 140, 300];
  const existingHeaders = [
    {
      title: "Client",
      icon: <i className="fa-regular fa-image"></i>,
      render: (p) => (
        <div className="d-flex">
          <div className="align-items-center">
            <img
              src={p.imageLink}
              alt="project img"
              style={{ width: "30px", height: "30px", marginRight: "10px" }}
            />
          </div>
          <div
            className="charlimit-no-width"
            style={{ paddingTop: "6px", maxWidth: "230px" }}
          >
            {p.name}
          </div>
        </div>
      ),
    },
    {
      title: "User",
      icon: <i className="fa-light fa-circle-user"></i>,
      render: (p) => (
        <div>
          <div className="underline">
            {p?.workspace?.length + 1 || 1}
            {` user${p?.workspace?.length + 1 > 1 ? "s" : ""}`}
          </div>
        </div>
      ),
      renderExpand: (p) => (
        <div className="admin-emails">
          <div className="mt--15 mb-20">
            {p?.clientEmail} <i className="fc-gold fa-solid fa-star"></i>
          </div>
          {p?.workspace?.map((w, k) => (
            <div key={k}>{w.email}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Invitation",
      icon: <i className="fa-light fa-notes"></i>,
      render: (p) => <div></div>,
      renderExpand: (p) => (
        <div className="admin-emails">
          <div className="mt--15 fc-green mb-20">Creator</div>
          {p?.workspace?.map((w, k) => (
            <div className={w.uid ? "fc-green" : "fc-grey"} key={k}>
              {w.uid ? "Accepted" : "Not yet accepted"}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Role",
      icon: <i className="fa-light fa-mask"></i>,
      render: (p) => <div></div>,
      renderExpand: (p) => (
        <div className="admin-emails">
          <div className="mt--15 mb-20">
            {p.role === "client" ? "Admin" : uppercase1stLetter(p.role)}
          </div>
          {p?.workspace?.map((w, k) => (
            <div key={k}>{uppercase1stLetter(w.role)}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Created or joined",
      icon: <i className="fa-light fa-notes"></i>,
      render: (p) => <div>{formatDateDDMMMYY(p.clientCreatedAt)}</div>,
      renderExpand: (p) => (
        <div className="admin-emails">
          <div className="mt--15 mb-20">
            {formatDateDDMMMYY(p.clientCreatedAt)}
          </div>
          {p?.workspace?.map((invitee, k) => (
            <div key={k}>{formatDateDDMMMYY(invitee.createdAt)}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Plan",
      icon: <i className="fa-light fa-notes"></i>,
      render: (p, i) => (
        <div className="d-flex segment-funnel-dropdown-colours">
          <div style={{ marginTop: "12px", width: "223px" }}>
            {p?.planLoading ? (
              <Spinner
                style={{
                  height: "50px",
                  width: "50px",
                  marginTop: "0px",
                  marginBottom: "6.75px",
                }}
              />
            ) : (
              <CustomDropdown
                onChange={async (o) => {
                  changePaymentPlan(o, p);
                }}
                value={p?.plan}
                placeholder={"Payment Plan"}
                unstyled
                options={paymentPlanOptions}
                menuOpenState={p?.dropdownOpen}
                setMenuOpenState={(e) => {
                  handleStateChange(
                    e,
                    "dropdownOpen",
                    "id",
                    p?.id,
                    setProjects,
                  );
                  handleStateChange(
                    e,
                    "dropdownOpen",
                    "id",
                    p?.id,
                    setFilteredProjects,
                  );
                }}
              />
            )}
          </div>
          <button
            onClick={() => {
              handleStateChange(
                !p.expanded,
                "expanded",
                "id",
                p?.id,
                setProjects,
              );
              handleStateChange(
                !p.expanded,
                "expanded",
                "id",
                p?.id,
                setFilteredProjects,
              );
            }}
            className="admin-expand-chevron"
          >
            {p.expanded ? (
              <i className="fc-black fa-solid fa-chevron-up"></i>
            ) : (
              <i className="fc-black fa-solid fa-chevron-down"></i>
            )}
          </button>
        </div>
      ),
    },
  ];

  const awaitingWidths = [270, 185, 250, 400];
  const awaitinggHeaders = [
    {
      title: "Main User’s email",
      icon: <i className="fa-light fa-star"></i>,
      render: (p) => (
        <div>
          <div className="wrapword">
            {p?.clientEmail ?? p?.email ?? "No email"}
          </div>
        </div>
      ),
    },
    {
      title: "Main url",
      icon: <i className="fa-light fa-link"></i>,
      render: (p) =>
        p.type !== "client" && (
          <a
            target="_blank"
            rel="noreferrer"
            className="fc-black wrapword"
            href={p?.rootUrl}
          >
            {p?.rootUrl}
          </a>
        ),
    },
    {
      title: "Plan",
      icon: <i className="fa-light fa-notes"></i>,
      render: (p, i) => (
        <div className="d-flex segment-funnel-dropdown-colours">
          <div style={{ marginTop: "12px", width: "223px" }}>
            {p?.planLoading ? (
              <Spinner
                style={{
                  height: "50px",
                  width: "50px",
                  marginTop: "0px",
                  marginBottom: "6.75px",
                }}
              />
            ) : (
              <CustomDropdown
                onChange={async (o) => {
                  changePaymentPlanAwaiting(o, p);
                }}
                value={p?.plan}
                placeholder={"Payment Plan"}
                unstyled
                options={paymentPlanOptions}
                menuOpenState={p?.dropdownOpen}
                setMenuOpenState={(e) => {
                  handleStateChange(
                    e,
                    "dropdownOpen",
                    "index",
                    i,
                    setAwaitingApprovals,
                  );
                }}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      title: "",
      icon: <></>,
      render: (p) => (
        <div
          className="fs-14"
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <button
            onClick={async () =>
              p.type === "client"
                ? handleClientApproveClick(p)
                : handleApproveClick(p)
            }
            className="btn-primary appa-btn mr-8 fw-700"
          >
            Approve
          </button>
          <button
            onClick={async () =>
              p.type === "client"
                ? handleClientDenyClick(p)
                : handleDenyClick(p)
            }
            className="btn-secondary appa-btn fw-700"
          >
            Deny
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <ToastContainer />
      <div className="d-flex">
        {tabItems.map((item) => {
          return (
            <div key={item.label}>
              <div className="tab-item" key={item.label}>
                <div
                  onClick={() => {
                    if (activeTab !== item.label) {
                      setIsLoading(true);
                      setActiveTab(item.label);
                    }
                  }}
                  className={
                    activeTab === item.label
                      ? "fw-700 fc-blue fs-16"
                      : "fw-500 fc-black fs-16"
                  }
                >
                  {item.label}
                </div>
              </div>
              <div
                className="tab-bar-insights"
                style={{
                  background: activeTab === item.label ? "#2A00FF" : "none",
                  width: activeTab === "insights" ? "65%" : "85%",
                }}
              />
            </div>
          );
        })}
      </div>
      <hr className="mt-5 mb-50" />
      <div className="fs-24 fw-700 lh-243 mb-3 fc-black mb-50">
        Access & Plans
      </div>

      {activeTab === "Exisiting clients" ? (
        <>
          <div style={{ height: "60px" }} className="row mb-32 mt-5 w-100 vhc">
            <div className="col-2">
              <CustomInput
                placeholder="Filter by company, user or project"
                containerClassName="observation-text-input-container half-width"
                inputClassNames="w-100"
                className={filter === "" ? "active" : ""}
                valueState={filter}
                onChange={(e) => handleFilterChange(e.target.value)}
                value={filter}
                disabled={isLoading}
              />
            </div>
            <div className="col-10"> </div>
          </div>
          {isLoading ? (
            <Spinner
              style={{ height: "75px", width: "75px", marginTop: "0px" }}
            />
          ) : (
            <AdminTable
              widths={existingWidths}
              headers={existingHeaders}
              rows={filteredProjects}
            ></AdminTable>
          )}
        </>
      ) : (
        <>
          {isLoading ? (
            <Spinner
              style={{ height: "75px", width: "75px", marginTop: "0px" }}
            />
          ) : (
            <AdminTable
              widths={awaitingWidths}
              headers={awaitinggHeaders}
              rows={awaitingApprovals}
            ></AdminTable>
          )}
        </>
      )}
    </div>
  );
}
